import React from 'react';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import styles from './load-modal.css';
import Box from '../box/box.jsx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import placeHolder from '././place_holder.png';
import closeIcon from './close_icon.png';
import {
  closeLoadPopModal
} from '../../reducers/modals';

import { setMarsProject } from '../../reducers/project-state';
import { getAllProject } from '../../lib/marsbox-api';

class LoadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workParams: {}
    };

    bindAll(this, [
      'handleCloseClick',
      'handleItemClick'
    ]);
  }
  componentWillMount() {
    getAllProject().then(data => this.setState({ workParams: data }));
  }

  handleCloseClick() {
    this.props.onCloseLoadPop();
  }

  handleItemClick(item) {
    this.props.onLoadProject(item.id, item.file_url);
    this.props.onCloseLoadPop();
  }

  render() {

    return (
      <Box>
        <div className={classNames(styles.saveModalWrapper)}>
          <div className={classNames(styles.saveModal)}>
            <div className={classNames(styles.saveModalTitle)}>我的作品<span className={classNames(styles.closeIconBox)} onClick={this.handleCloseClick}><img className={classNames(styles.closeIcon)} src={closeIcon} /></span></div>
            <div className={classNames(styles.myWorkBox)}>
              {this.state.workParams.list && this.state.workParams.list.length > 0 ? this.state.workParams.list.map(user => {
                return (
                  <div key={user.id} className={classNames(styles.myWorkSingle)} onClick={() => {this.handleItemClick(user)}}>
                    <div className={classNames(styles.workBg)}>
                      <img className={classNames(styles.workBgImg)} src={user.image.o}/>
                    </div>
                    <div className={classNames(styles.workBottom)}>
                      <div className={classNames(styles.workBottomBox)}>
                        <h1 className={classNames(styles.workBottomTitle)}>{user.name}</h1>
                        <span className={classNames(styles.workBottomTypeBox)}>
                          <strong className={classNames(styles.workBottomType)}>Scratch</strong>
                        </span>
                      </div>
                      <div className={classNames(styles.workInfo)}>
                        <div className={classNames(styles.workInfoAvatar)}>
                          <span className={classNames(styles.workInfoAvatarBox)}>
                            <img className={classNames(styles.workInfoImg)} src={user.user.avatar} alt="火星科学盒" />
                          </span >
                          <h3 className={classNames(styles.workInfoName)}>{user.user.nickname}</h3>
                        </div>
                        <h2 className={classNames(styles.workInfoTime)}>{moment(parseInt(user.file_last_update_time * 1000)).format("YYYY-MM-DD")}</h2>
                      </div>
                    </div>
                  </div>
                )
              }) : (
                  <div className={classNames(styles.placeHolder)}>
                    <img className={classNames(styles.placeHolderImg)} src={placeHolder} alt="火星科学盒" />
                    <h4 className={classNames(styles.placeHolderFont)}>您还未上传作品哦！<br />快去创作我的新作品！</h4>
                  </div>
                )}
            </div>
          </div>
          <div className={classNames(styles.mask)}></div>
        </div>
      </Box>
    )
  }
};

LoadModal.propTypes = {
  onCloseLoadPop: PropTypes.func,
  onLoadProject: PropTypes.func
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => ({
  onCloseLoadPop: () => dispatch(closeLoadPopModal()),
  onLoadProject: (id, uri) => dispatch(setMarsProject(id, uri))
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LoadModal);